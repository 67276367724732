(function($) {
    var Controller ={
        standby:function(){
            this.view = {};
            this.view.window = $(window);
            this.age_gate();
            this.view.window.on('load resize', this.imgRespon.bind(this));
            this.view.window.on('load', this.hamburger.bind(this));
            this.view.window.on('load resize', this.slick.bind(this));
            this.view.window.on('load', this.ancrScroll.bind(this));
            this.view.window.on('load', this.pageLoaded.bind(this));
            this.view.window.on('load', this.scrollAddClass.bind(this));
            this.view.window.on('load', this.parallax.bind(this));
        },
        imgRespon:function(){
            if(matchMedia('(min-width: 768px)').matches){
                $('.respon').each(function(){
                    $(this).attr('src',$(this).attr('src').replace('.sp','.pc'));
                });
            }else{
                $('.respon').each(function(){
                    $(this).attr('src',$(this).attr('src').replace('.pc','.sp'));
                });
            }
		},
        hamburger:function(){
            $(".hamburger").on("click",function(){
                let target = $(this).data('navigation');
                $(this).toggleClass("active");
                $('#'+target).toggleClass("active");
            });
        },
        slick:function(){
            if(!$("#slider").get(0)){
                return;
            }
            function sliderSetting(){
                var width = $(window).width();

                if(width <= 767){
                    $('.slides').not('.slick-initialized').slick();
                } else {
                    $('.slides.slick-initialized').slick('unslick');
                }
            }
            sliderSetting();
        },
        age_gate:function(){
            /* ---------------------------------
            年齢確認 modal表示
            ---------------------------------- */
            AgeModle("ja","corporate");
            //age_gate Script を追加
            function AgeModle(language,cookiename){
                var jstag = $('<script>');
                jstag.attr({
                    src : "/assets/js/age_gate.js?lang="+language+"&sitename="+cookiename
                });
                jstag.appendTo('head');
            }
        },
        ancrScroll:function(){
            $('.ancr').on('click',function(event){
                (event.preventDefault) ? event.preventDefault():event.returnValue=false;
                let target = $(this).attr('href');
                let scrollPosition = $(target).offset().top;
                $('html,body').animate({scrollTop:scrollPosition},1000);
            })
        },
        pageLoaded:function(){
            $('html').addClass('page-loaded');
        },
        scrollAddClass:function(){
            var controller = new ScrollMagic.Controller();
            var $targets = $('.scrollmagic');
            $targets.each(function() {
                var $target = $(this);
                var scene = new ScrollMagic.Scene({
                    triggerElement : $target.get(0),
                    triggerHook : 'onEnter',
                    offset : 200
                });
                scene.on('enter', function() {
                    $target.addClass('magic')
                })
                .on("leave", function () {
                    $target.removeClass('magic')
                })
                .addTo(controller);
            });
        },
        parallax:function(){
            //メインビジュアル
            var controller = new ScrollMagic.Controller();
            var scene = new ScrollMagic.Scene({
                triggerElement: ".top-parallax",
                triggerHook: "onLeave",
                duration: $(window).innerHeight()
            })
            .setTween(".top-parallax .top-parallaxTarget", 1, {
                top: "30%"
            })
            .addTo(controller);

            //通常
            var controller02 = new ScrollMagic.Controller();
            var $targets = $('.parallax');
            $targets.each(function() {
                var $target = $(this);
                var scene = new ScrollMagic.Scene({
                    triggerElement: $target.get(0),
                    triggerHook: "onEnter",
                    duration: $(window).innerHeight()
                })
                .setTween($(this).find(".parallax-target"), 1, {
                    top: "30%"
                })
                .addTo(controller02);
            });
        },
    };
    Controller.standby();
})(jQuery);
